import { initializeApp } from "firebase/app";
import { getDatabase, update, ref, set } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAP360sZbNjhMqKZQ__ygHwmJGLpANkALM",
    authDomain: "medbot-e11d9.firebaseapp.com",
    databaseURL: "https://medbot-e11d9-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "medbot-e11d9",
    storageBucket: "medbot-e11d9.appspot.com",
    messagingSenderId: "346676230757",
    appId: "1:346676230757:web:ba67b268525e6d6025248c",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getDatabase(firebaseApp);

// generic db update function for single path
export const updateDb = async (path: string, updateVal: any) => {
    const updates: any = {
        [`${path}`]: updateVal,
    };
    try {
        await update(ref(db), updates);
    } catch (e) {
        console.error(e);
    }
};

export const setDb = async (path: string, val: any) => {
    console.log("is this being called?", path);
    try {
        set(ref(db, path), val);
    } catch (e) {
        console.error(e);
    }
};
