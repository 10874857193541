import { ChatContainer } from "./ChatContainer";
import { ChatMessage } from "./ChatMessage";
import { ChatInputBar } from "./ChatInputBar";
import { ChatMessageType } from "../../types/ChatMessageType";
import { ConversationFunctionVer } from "../../types/ConversationFunctionVer";

interface ChatUIProps {
    messages: ChatMessageType[];
    callback: (question: string, chatId: string, version?: ConversationFunctionVer) => void;
}

export const ChatUI: React.FC<ChatUIProps> = ({ messages, callback }: ChatUIProps) => {
    return (
        <>
            <ChatContainer>
                {messages.map((message, index) => (
                    <ChatMessage key={index} type={message.type} text={message.text} />
                ))}
            </ChatContainer>
            <ChatInputBar callback={callback} />
        </>
    );
};
