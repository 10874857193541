import { useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "./useLocalStorage";
import { setDb } from "../firebase/firebaseApp";

export const useUUID = () => {
    const UNDEFINED_STRING = useMemo(() => "_)_(9-090--undefined-342342342", []);
    const [storedValue, setValue] = useLocalStorage("uuid", UNDEFINED_STRING);

    useEffect(() => {
        if (storedValue === UNDEFINED_STRING) setValue(uuidv4());
    }, [setValue, storedValue, UNDEFINED_STRING]);

    const refreshUuid = () => {
        const uuid = uuidv4();
        setValue(uuid);
        setDb(`Chats/${uuid}`, { chatId: uuid });
    };

    return { uuid: storedValue, refreshUuid };
};
