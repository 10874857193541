import { useContext } from "react";
import { ChatUI } from "./ChatUI";
import { useDebug } from "../../hooks/useDebug";
import { ChatContext } from "../../context/ChatsContext";
import { userMessage } from "../../firebase/httpsCallable";

const ChatApp: React.FC = () => {
    const { state } = useContext(ChatContext);
    const { debug } = useDebug();

    if (debug) console.log("state", state);
    return (
        <ChatUI
            messages={state?.chatMessages ? Object.values(state.chatMessages) : []}
            callback={userMessage}
        />
    );
};

export default ChatApp;
