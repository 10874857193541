import React, { createContext, useState, useEffect } from "react";
import { db, updateDb } from "../firebase/firebaseApp";
import { onValue, ref, off, push, set } from "firebase/database";
import { ChatMessageType } from "../types/ChatMessageType";

const CHAT_PATH = "Chats";

export interface ChatState {
    chatId: string;
    chatMessages: ChatMessageType[];
}

interface ChatContextProps {
    state: ChatState;
    setState: (state: ChatState) => void;
    chatId: string;
}

const initialState: ChatState = {
    chatId: "",
    chatMessages: [],
};

export const ChatContext = createContext<ChatContextProps>({
    state: initialState,
    setState: () => {},
    chatId: "",
});

// Automatically update state when db changes
// https://gist.github.com/pflevy/3ab3668b062812252f7d0c0a8279774d
export const ChatProvider = ({
    chatId,
    children,
    debug = false,
}: {
    chatId: string;
    children: React.ReactNode;
    debug?: boolean;
}) => {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        const stateRef = ref(db, `${CHAT_PATH}/${chatId}`);

        onValue(stateRef, (snapshot) => {
            if (debug) console.log("chat state changed");
            if (snapshot.exists()) {
                setState(snapshot.val());
            }
        });
        return () => off(stateRef);
    }, [debug, chatId]);

    return <ChatContext.Provider value={{ state, setState, chatId }}>{children}</ChatContext.Provider>;
};

// updates the db and which in turn updates the state
export const modifyMessage = (message: string, chatId: string) => {
    updateDb(`${CHAT_PATH}/${chatId}/message`, message);
};

export const pushChatMessage = (chatId: string, message: ChatMessageType) => {
    const chatMessageRef = ref(db, `${CHAT_PATH}/${chatId}/chatMessages`);
    const newChatMessageRef = push(chatMessageRef);
    set(newChatMessageRef, message);
};

export const replaceMessage = (chatId: string, messageId: string, message: ChatMessageType) => {
    updateDb(`${CHAT_PATH}/${chatId}/chatMessages/${messageId}`, message);
};
