import { useUUID } from "../../hooks/useUUID";
import { Button } from "@mui/material";
export const Uuid = () => {
    const { uuid, refreshUuid } = useUUID();

    return (
        <div>
            <h1>UUID</h1>
            <p>{uuid}</p>
            <Button onClick={refreshUuid} variant="contained">
                Refresh
            </Button>
        </div>
    );
};
