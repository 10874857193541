import { Box, Typography } from "@mui/material";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);
    return (
        <Box margin={2} textAlign="center">
            <Typography variant="h1">Oops!</Typography>
            <Box component="p">Sorry, an unexpected error has occurred.</Box>
            <Box component="p">
                <Box fontStyle="italic">
                    {isRouteErrorResponse(error) && `${error.status}: ${error.statusText}`}
                </Box>
            </Box>
        </Box>
    );
}
