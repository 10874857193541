import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../../context/AppContext";
import { ReactNode, useContext, useEffect, useState } from "react";

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

export const ChatContainer: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const theme = useTheme();
    const {
        state: { minimized },
    } = useContext(AppContext);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Box
            width="100%"
            height={windowDimensions.height - parseInt(theme.spacing(20))}
            border="1px solid"
            bgcolor={theme.palette.background.paper}
            overflow="auto"
            display={minimized ? "none" : "flex"}
            flexDirection="column-reverse"
        >
            <Box>{children}</Box>
        </Box>
    );
};
