import { useContext, useState } from "react";
import {
    ChatContext,
    ChatProvider,
    pushChatMessage,
    replaceMessage,
    ChatState,
} from "../../context/ChatsContext";
import { Box, Button } from "@mui/material";

export const ChatContextTest = () => {
    return (
        <ChatProvider chatId="abc" debug>
            <ChatContextDisplay />
        </ChatProvider>
    );
};

const ChatContextDisplay = () => {
    const { state, chatId } = useContext(ChatContext);

    const [count, setCount] = useState(0);

    const { chatMessages } = state;

    const chatMessagesArr = Object.values(chatMessages);

    chatMessagesArr.map((value) => console.log(value));

    const getLastMessageId = (state: ChatState) => {
        const chatMessagesKeys = Object.keys(state.chatMessages);
        const lastKey = chatMessagesKeys[chatMessagesKeys.length - 1];
        return lastKey;
    };

    return (
        <Box>
            <h1>ChatContextTest</h1>
            <p>chatId: {chatId}</p>
            <p>chatId: {state.chatId}</p>
            <Button
                variant="contained"
                onClick={() => {
                    pushChatMessage("abc", { type: "user", text: "hello world" + count });
                    setCount(count + 1);
                }}
            >
                modifyMessage
            </Button>

            <Button
                variant="contained"
                onClick={() => {
                    replaceMessage("abc", "-NTx_UMPZ7AbHRIwjHWb", { type: "user", text: "some message" });
                }}
            >
                replace message
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    console.log("lastKey", getLastMessageId(state));
                }}
            >
                Get last messageId
            </Button>
        </Box>
    );
};
