import { firebaseApp } from "./firebaseApp";
import { HttpsCallable, getFunctions, httpsCallable } from "firebase/functions";
import { ConversationFunctionVer } from "../types/ConversationFunctionVer";

const conversationFunction: HttpsCallable = httpsCallable(getFunctions(firebaseApp), "conversationFunction");
const conversationV2Function: HttpsCallable = httpsCallable(
    getFunctions(firebaseApp),
    "conversationV2Function"
);

export const userMessage = async (
    question: string,
    chatId: string,
    version: ConversationFunctionVer = "v2"
) => {
    const data: any =
        version === "v1"
            ? await conversationFunction({ question, chatId })
            : await conversationV2Function({ question, chatId });
    return data?.data?.res?.text;
};
