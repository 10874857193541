import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ChatMessageType, MessageTypes } from "../../types/ChatMessageType";
import { FadeInOut } from "../FadeInOut";
import ReactMarkdown from "react-markdown";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

function convertUrlsToMarkdownLinks(inputString: string) {
    let regex = /(https:\/\/www\.minish\.co\.kr\/media\/image\/BD13\/\d\/(\d+))/g;

    return inputString.replace(regex, function (match, url, number) {
        return `[image - ${number}](${url})`;
    });
}

export const ChatMessage: React.FC<ChatMessageType> = ({ type = "user", text = "" }) => {
    const theme = useTheme();
    const userTypes: MessageTypes[] = ["user"];
    const botTypes: MessageTypes[] = ["bot", "botWaiting"];
    const systemTypes: MessageTypes[] = ["system", "systemWaiting"];
    const waitingTypes: MessageTypes[] = ["botWaiting", "systemWaiting"];
    const {
        state: { conversationFunctionVer },
    } = useContext(AppContext);

    return (
        <Box
            width="100%"
            display="flex"
            justifyContent={
                userTypes.includes(type) ? "flex-start" : botTypes.includes(type) ? "flex-end" : "center"
            }
        >
            <Box
                padding={2}
                borderRadius={2}
                margin={2}
                border="1px solid"
                bgcolor={
                    userTypes.includes(type)
                        ? theme.palette.primary.main
                        : botTypes.includes(type)
                        ? theme.palette.secondary.main
                        : "white"
                }
                color={systemTypes.includes(type) ? theme.palette.grey[500] : "white"}
                borderColor={systemTypes.includes(type) ? theme.palette.grey[500] : theme.palette.grey[300]}
            >
                {waitingTypes.includes(type) ? (
                    <FadeInOut />
                ) : (
                    <Box>
                        {conversationFunctionVer === "v2" ? (
                            <ReactMarkdown>{convertUrlsToMarkdownLinks(text)}</ReactMarkdown>
                        ) : (
                            text
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
