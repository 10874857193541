import React, { createContext, useState } from "react";
import { ConversationFunctionVer } from "../types/ConversationFunctionVer";

export interface AppState {
    version: number;
    conversationFunctionVer: ConversationFunctionVer;
    debug: boolean;
    minimized: boolean;
}

interface AppContextProps {
    state: AppState;
    setState: (state: AppState) => void;
}

const initialState: AppState = {
    version: 0.1,
    conversationFunctionVer: "v1",
    minimized: false,
    debug: true, // set debug flag here
};

export const AppContext = createContext<AppContextProps>({
    state: initialState,
    setState: () => {},
});

// Automatically update state when db changes
// https://gist.github.com/pflevy/3ab3668b062812252f7d0c0a8279774d
export const AppProvider = ({ children, debug = false }: { children: React.ReactNode; debug?: boolean }) => {
    const [state, setState] = useState(initialState);
    return <AppContext.Provider value={{ state, setState }}>{children}</AppContext.Provider>;
};

export const toggleMinimize = (state: AppState, setState: (state: AppState) => void) => {
    setState({ ...state, minimized: !state.minimized });
};

export const toggleConversationFunctionVer = (state: AppState, setState: (state: AppState) => void) => {
    setState({
        ...state,
        conversationFunctionVer: state.conversationFunctionVer === "v1" ? "v2" : "v1",
    });
};
