import { AppProvider } from "../context/AppContext";
import { ChatProvider } from "../context/ChatsContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useUUID } from "../hooks/useUUID";
import ChatApp from "../components/ChatApp/ChatApp";
import { TopBar } from "../components/TopBar";
import { Box } from "@mui/material";

const App: React.FC = () => {
    const { uuid, refreshUuid } = useUUID();
    const theme = createTheme();
    return (
        <AppProvider>
            <ThemeProvider theme={theme}>
                {/* <Box width={theme.spacing(40)}> */}
                <Box width={"100%"}>
                    <TopBar refreshUuid={refreshUuid} />
                    <ChatProvider chatId={uuid}>
                        <ChatApp />
                    </ChatProvider>
                </Box>
            </ThemeProvider>
        </AppProvider>
    );
};

export default App;
