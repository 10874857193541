// React component with props in Typescript
// Path: src/components/TextInput.tsx
import { Button, Box, InputAdornment, TextField } from "@mui/material";
// import { AppContext } from "../../context/AppContext";
import { ChatContext } from "../../context/ChatsContext";
import { AppContext } from "../../context/AppContext";
import { useState, useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Send as SendIcon } from "@mui/icons-material";
import { ConversationFunctionVer } from "../../types/ConversationFunctionVer";

interface ChatInputBarProps {
    callback: (question: string, chatId: string, version?: ConversationFunctionVer) => void;
}

export const ChatInputBar: React.FC<ChatInputBarProps> = ({ callback }) => {
    const theme = useTheme();
    const [text, setText] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(true);

    const { state, chatId } = useContext(ChatContext);
    const {
        state: { minimized, conversationFunctionVer },
    } = useContext(AppContext);

    const clearAndCallback = () => {
        setText("");
        callback(text, chatId, conversationFunctionVer);
    };

    useEffect(() => {
        const chatMessages = state?.chatMessages ? Object.values(state.chatMessages) : [];

        const lastChatMessage = chatMessages[chatMessages.length - 1];

        setDisabled(
            lastChatMessage?.type === "systemWaiting" || lastChatMessage?.type === "botWaiting" || text === ""
                ? true
                : false
        );
    }, [state, state.chatMessages, text]);

    return (
        <Box
            width="100%"
            height={theme.spacing(10)}
            border="1px solid"
            bgcolor={theme.palette.grey[300]}
            display={minimized ? "none" : "flex"}
        >
            <Box margin={1.5} width="100%">
                <TextField
                    sx={{ backgroundColor: "white" }}
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    onKeyDown={(e) => {
                        e.key === "Enter" && !disabled && clearAndCallback();
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    variant="contained"
                                    endIcon={<SendIcon />}
                                    disabled={disabled}
                                    onClick={clearAndCallback}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
    );
};
