import { Box, Toolbar, Typography, Button, ButtonGroup } from "@mui/material";
import { AppContext, toggleConversationFunctionVer } from "../context/AppContext";
// import { AppContext, toggleMinimize } from "../context/AppContext";
import { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
    RestartAltOutlined as RestartIcon,
    // CloseFullscreenOutlined as MinimizeIcon,
    // OpenInFullOutlined as MaximizeIcon,
    LooksOne,
    LooksTwo,
} from "@mui/icons-material";

export const TopBar = ({ refreshUuid }: { refreshUuid: () => void }) => {
    // const { state, setState } = useContext(AppContext);
    const { state, setState } = useContext(AppContext);
    const theme = useTheme();
    return (
        <Box
            sx={{ flexGrow: 1 }}
            width="100%"
            height={theme.spacing(10)}
            border="1px solid black"
            bgcolor={theme.palette.info.main}
        >
            <Toolbar>
                <Typography component="div" sx={{ flexGrow: 1 }} color="white" fontWeight="bold">
                    [🔬PROTOTYPE] Minish CRM bot
                </Typography>

                <Box mr={2}>
                    <Box display="inline-block" mr={1}>
                        <Typography variant="caption" color="white">
                            ver:
                        </Typography>
                    </Box>
                    <ButtonGroup
                        variant="contained"
                        color="success"
                        onClick={() => toggleConversationFunctionVer(state, setState)}
                    >
                        <Button size="small" disabled={state.conversationFunctionVer !== "v1"}>
                            <LooksOne />
                        </Button>
                        <Button size="small" disabled={state.conversationFunctionVer !== "v2"}>
                            <LooksTwo />
                        </Button>
                    </ButtonGroup>
                </Box>

                <ButtonGroup variant="contained" color={state.minimized ? "success" : "error"}>
                    {!state.minimized && (
                        <Button onClick={refreshUuid} size="small">
                            <RestartIcon />
                        </Button>
                    )}
                    {/* <Button onClick={() => toggleMinimize(state, setState)} size="small">
                        {state.minimized ? <MaximizeIcon /> : <MinimizeIcon />}
                    </Button> */}
                </ButtonGroup>
            </Toolbar>
        </Box>
    );
};
