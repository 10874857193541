import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./routes/ErrorPage";
import { ChatContextTest } from "./routes/tests/ChatContextTest";
import { Uuid } from "./routes/tests/Uuid";
import App from "./routes/App";
import "./css/index.css";

console.log("process.env.REACT_APP_STANDALONE", process.env.REACT_APP_STANDALONE);
const router = createBrowserRouter(
    process.env.REACT_APP_STANDALONE
        ? [
              { path: "/", element: <App />, errorElement: <ErrorPage /> },
              { path: "/test", element: <ChatContextTest />, errorElement: <ErrorPage /> },
              { path: "/uuid", element: <Uuid />, errorElement: <ErrorPage /> },
          ]
        : [{ path: "*", element: <App />, errorElement: <ErrorPage /> }]
);

const rootElement = () => {
    const returnEl = document.createElement("div");
    returnEl.id = "react-chrome-app";
    returnEl.style.cssText += `  position: fixed;
                                    right: 0;
                                    bottom: 0; 
                                    background: #ffffff;
                                    z-index: 999;`;

    document.body.appendChild(returnEl);
    return returnEl;
};

const root = ReactDOM.createRoot(
    process.env.REACT_APP_STANDALONE ? (document.getElementById("root") as HTMLElement) : rootElement()
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
